import React, { useState, useEffect } from 'react';
import './PrizeChallenge.scss';
import trophy from './trophy.svg';
import TruncatedText from './TruncatedText';
import mongo from './mongodb.webp';
// import mongomobile from './mongomobile.png';
import godaddy from './godaddy-registry.webp';
import godaddyMobile from './godaddy.svg';
import auth0 from './auth0.webp';
import streamlit from './streamlit.webp';
import tinymce from './tinymce.webp';
import upsurge from './upsurge.webp'
import logitech from './logitech.webp'
import tezos from './tezos.png'
import voiceflow from './voiceflow.webp'

const PrizeChallenges = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const challengesByType = {
    "SPONSORS Prizes/BOUNTY": [
      
     {
      name:'Best Use of LemmeBuild- by Upsurge Labs',
      description:"Build an Innovative API with Real-World Applications Using LemmeBuild",
      prize:"Prize pool of $2000 ",
      imageUrl: upsurge,
      challenge_details:` Participants are challenged to create a custom API using LemmeBuild and integrate it into a dynamic frontend application. The project should showcase an innovative solution with a clear real-world use case, such as automating processes, improving user experience, or solving a practical problem. The API should be secure, scalable, and optimized for performance, with seamless integration into the frontend. Ensure the solution is well-documented, demonstrating how it addresses real-world challenges through its functionality and design.<br/> <br/><b> Top 2 winners (real word use case -$500 each).<br/> Pool Prize:$1000-(Top 10 projects -excluding Top 2 winners)</b></br></br><a href="https://youthful-hickory-3e4.notion.site/LemmeBuild-by-Upsurge-Labs-e51df5e7f8ad4992b479003b836621de" style="color: #FF885B;background: #343131;" target="_blank" rel="noopener noreferrer"><b>Problem Statement</b></a>`
     },
     {
      name: 'Logitech MX Macro Challenge',
      description: "Participate in Logitech MX Macro Challenge",
      prize: "Logitech MX Keys S and Logitech peripherals!",
      imageUrl: logitech,
      challenge_details: `<a href="https://docs.google.com/forms/d/e/1FAIpQLSeogmdlnE97P9yVxUAI9c8WxJH2YO5KvAhFIWD0VtgAElNR0Q/formResponse" style="color: #FF885B;background: #343131;" target="_blank" rel="noopener noreferrer"><b>Problem Statement</b></a>`
      },
     {
      name: 'Best Use of Tezos',
      description: "Build a project that has implemented Tezos",
      prize: "Prize pool of $700",
      imageUrl: tezos,
      challenge_details: `Participants are challenged to build a project that has implemented Tezos blockchain technology. The project should demonstrate the unique features and capabilities of Tezos, showcasing its potential for decentralized applications, smart contracts, and digital assets. The solution should be innovative, secure, and scalable, with a clear use case that highlights the benefits of Tezos in real-world applications. Ensure the project is well-documented, explaining how Tezos was integrated and the advantages it offers over other blockchain platforms. 
          <br/><br/>Please review the <a href="https://spring-telescope-794.notion.site/Terms-and-Condition-of-Tezos-challenge-100e927b475680ae904ada8a3b31bc49" target="_blank" rel="noopener noreferrer"><b>Terms and Conditions</b></a> for the Tezos challenge.`
       },
       {
        name: 'Best use of VoiceFlow',
        description: "Build a project that has implemented VoiceFlow",
        prize: "Free  trial to 3 months + add 2 million additional tokens ",
        imageUrl: voiceflow,
        challenge_details: `<a href="https://spring-telescope-794.notion.site/Problem-Statement-Of-Voiceflow-Challenge-9558450434424712b514e70f1ec6ba62?pvs=25" style="color: #FF885B;background: #343131;" target="_blank" rel="noopener noreferrer"><b>Problem Statement</b></a>`
   
        
       
        },
    ],
    "MLH Prize": [
      {
        name: 'Best Use of MongoDB Atlas',
        description: 'Build your hack using MongoDB Atlas to host a database in the cloud!',
        prize: 'M5GO IoT Starter Kit',
        imageUrl: windowWidth <= 768 ? mongo : mongo,
        challenge_details: "MongoDB Atlas takes the leading modern database and makes it accessible in the cloud! Get started with a $50 credit for students or sign up for the Atlas free forever tier (no credit card required). Along with a suite of services and functionalities, you'll have everything you need to manage all of your data, and you can get a headstart with free resources from MongoDB University! Build a hack using MongoDB Atlas for a chance to win a M5GO IoT Starter Kit for you and each member of your team."
   ,
      },
      {
        name: 'Best Domain Name from GoDaddy Registry',
        description: 'GoDaddy Registry is giving you a free domain name of your choice! (online mode only)',
        prize: 'Hack from Home Kit',
         challenge_details:"GoDaddy Registry is giving you everything you need to be the best hacker no matter where you are. Register your domain name with GoDaddy Registry for a chance to win a Hack from Home Kit! Each Kit contains wireless earbuds, blue light glasses, selfie ring light and a pouch for easy transport.",
      imageUrl: windowWidth <= 768 ? godaddyMobile : godaddy,
      },
      {
        name: 'Best Use of Auth0',
        description: 'Use Auth0 in your hack for seamless authentication',
        prize: 'Wireless Headphones & Battery Pack',
        imageUrl: auth0,
       challenge_details:"Auth0 wants your applications to be secure! Why spend hours building features like social sign-in, Multi-Factor Authentication, and passwordless log-in when you can enable them through Auth0 straight out of the box? Auth0 is free to try, doesn’t require a credit card, and allows for up to 7,000 free active users and unlimited log-ins. Make your new account today and use any of the Auth0 APIs for a chance to win a pair of wireless headphones and a battery pack for you and each member of your team!"
     },
      {
        name: 'Best Use of Streamlit',
        description: 'Use Streamlit in your hack by creating an app/webapp...',
        prize: 'Arduino Tiny ML Kit',
        imageUrl: streamlit,
       challenge_details:"Want to take your Python skill to the next level? Streamlit is an open-source Python library that makes it easy to share and build custom web apps for machine learning, data science, and more. Instead of writing Python scripts without a UI, you can create a beautiful web app, complete with widgets and data visualizations, and deploy it for free to the Streamlit Community Cloud. You can even build a fully functional LLM-powered app with just 25 lines of code! Hack with Streamlit this weekend for your chance to win an Arduino Tiny ML Kit for you and each member of your team. "
     },
      {
        name: 'Best Use of TinyMCE',
        description: 'Use TinyMCE in your hack to enable a rich text editor',
        prize: '3D Printing Pen',
        imageUrl: tinymce,
       challenge_details:"TinyMCE is a rich-text editor that allows you to create formatted content within a hacker-friendly interface. Adding a Rich Text Editor component has never been easier! Alongside functionality like bold, italics, underlines, hyperlinks, or titles (to name a few), the editor also allows users to enable HTML tags, MarkDown or CSS. TinyMCE even gives you the ability to edit your text elements directly with no code, similar to the way you would edit a word document but with even more practical hackathon use cases. Signup for TinyMCE for a free trial and get started today, no credit card required! To qualify for the Best Use of TinyMCE prize category, be sure to render the editor with a Tiny Cloud account for a chance to win a 3D Printing Pen for you and each of your team members!"
      },
      // ... other MLH challenges
    ],
   
  };

  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [showDetails, setShowDetails] = useState({});

  const toggleDetails = (typeIndex, index) => {
    const id = `${typeIndex}-${index}`; // Create a unique id for each card
    setShowDetails((prevState) => ({
      ...prevState,
      [id]: !prevState[id], // Toggle the visibility of the specific card
    }));
  };

  return (
    <section className="sponsors-challenges-section">
      <div className="sponsors-challenges-title-container">
        <h2 className="sponsors-challenges-section-title">LET'S HACK</h2>
        <h1 className="sponsors-challenges-title">SPONSOR'S CHALLENGES</h1>
      </div>
      {Object.keys(challengesByType).map((challengeType, typeIndex) => (
        <div key={typeIndex}>
          <h2 className="challenge-type-title">{challengeType}</h2>
          <div className="sponsors-challenges-cards">
            {challengesByType[challengeType].map((challenge, index) => (
              <div
                className="sponsor-challenge-card"
                key={index}
                onMouseEnter={() => setHoveredIndex(index)}
                onMouseLeave={() => setHoveredIndex(null)}
              >
                <div className="challenge-content">
                  <div className="challenge-info">
                    <div className="challenge-name-container">
                      <h2 className="challenge-name">
                        <TruncatedText
                          text={challenge.name}
                          desktopMaxLength={20}
                          mobileMaxLength={17}
                          isHovered={hoveredIndex === index}
                        />
                      </h2>
                    </div>
                    <div className="challenge-description-container">
                      <p className="challenge-description">
                        <TruncatedText
                          text={challenge.description}
                          desktopMaxLength={50}
                          mobileMaxLength={27}
                          isHovered={hoveredIndex === index}
                        />
                      </p>
                    </div>
                    <p className="prize-info">
                      <img src={trophy} className="trophy-icon" alt="Trophy Icon" />
                      <span className="prize-amount">
                        <TruncatedText
                          text={challenge.prize}
                          desktopMaxLength={16}
                          mobileMaxLength={12}
                          isHovered={hoveredIndex === index}
                        />
                      </span>
                      <span className="winner-text">For Winner</span>
                    </p>
                    <button className="sponsors-view-more-btn" onClick={() => toggleDetails(typeIndex, index)}>
                      {showDetails[`${typeIndex}-${index}`] ? 'VIEW LESS' : 'VIEW MORE'}
                    </button>

                  </div>
                  <div className="challenge-image-container">
                    <img
                      loading="lazy"
                      src={challenge.imageUrl}
                      className="challenge-image"
                      alt={`${challenge.name} visual representation`}
                    />
                  </div>
                </div>
                <div className={`challenge-details ${showDetails[`${typeIndex}-${index}`] ? 'show' : ''}`}>
                  <div dangerouslySetInnerHTML={{ __html: challenge.challenge_details }} />
                </div>


              </div>
            ))}
          </div>
        </div>
      ))}
    </section>
  );
};

export default PrizeChallenges;
