import React from 'react';
import './Prizes.scss';
import Navbar from '../../components/Navbar/Navbar';
import img from './Prizes.svg';
import PrizePool from '../../components/PrizePool/PrizePool';
import PrizeChallenges from '../../components/PrizeChallenges/PrizeChallenges';
import Footer from '../../components/Footer/Footer';

const Prizes = () => {
  return (
    <>
    <div className="prizes-container">
      <Navbar />
      <section className="prizes-section">
        <img
          src={img}
          alt="Prizes session background"
          className="prizes-background"
        />
        <div className="prizes-content">
          <h1 className="prizes-title">PRIZES</h1>
          <p className="prizes-subtitle">CREATE | COLLABORATE | COMMUNICATE</p>
        </div>
        <div className="whitish-overlay"></div>
      </section>
      <div className="gradient-overlay"></div>
    </div>
    <PrizePool/>
    <PrizeChallenges/>
    <Footer/>
    </>
    
  );
};

export default Prizes;
