import React, { useCallback, useRef, useState } from 'react';
import './Swags.scss';
// import selmon from './selmonBhai.webp';
import mohit from './mohit.webp';
import swagCard from './Swag.webp';
import * as htmlToImage from "html-to-image";
import Desktop1 from './desktop/HTM1.webp';
import Desktop2 from './desktop/HTM2.webp';
import Desktop3 from './desktop/HTM3.webp';
import Desktop4 from './desktop/HTM4.webp';
import Desktop5 from './desktop/HTM5.webp';
import Desktop6 from './desktop/HTM6.webp';
import Desktop7 from './desktop/HTM7.webp';
import Desktop8 from './desktop/HTM8.webp';
import Desktop9 from './desktop/HTM9.webp';
import Desktop10 from './desktop/HTM10.webp';

import Mobile1 from './mobile/Mobile1.webp';
import Mobile2 from './mobile/Mobile2.webp';
import Mobile3 from './mobile/Mobile3.webp';
import Mobile4 from './mobile/Mobile4.webp';
import Mobile5 from './mobile/Mobile5.webp';
import Mobile6 from './mobile/Mobile6.webp';
import Mobile7 from './mobile/Mobile7.webp';
import Mobile8 from './mobile/Mobile8.webp';
import Mobile9 from './mobile/Mobile9.webp';
import Mobile10 from './mobile/Mobile10.webp';
import Mobile11 from './mobile/Mobile11.webp';


const data = {
  desktop: [
    { id: 1, image: Desktop1, alt: '' },
    { id: 2, image: Desktop2, alt: '' },
    { id: 3, image: Desktop3, alt: '' },
    { id: 4, image: Desktop4, alt: '' },
    { id: 5, image: Desktop5, alt: '' },
    { id: 6, image: Desktop6, alt: '' },
    { id: 7, image: Desktop7, alt: '' },
    { id: 8, image: Desktop8, alt: '' },
    { id: 9, image: Desktop9, alt: '' },
    { id: 10, image: Desktop10, alt: '' },
  ],
  mobile: [
    { id: 11, image: Mobile1, alt: '' },
    { id: 12, image: Mobile2, alt: '' },
    { id: 13, image: Mobile3, alt: '' },
    { id: 14, image: Mobile4, alt: '' },
    { id: 15, image: Mobile5, alt: '' },
    { id: 16, image: Mobile6, alt: '' },
    { id: 17, image: Mobile7, alt: '' },
    { id: 18, image: Mobile8, alt: '' },
    { id: 19, image: Mobile9, alt: '' },
    { id: 20, image: Mobile10, alt: '' },
    { id: 21, image: Mobile11, alt: '' },
  ],
};

const Swags = () => {
  const [activeTab, setActiveTab] = useState('desktop');
  const [userImage, setUserImage] = useState("");
  // const [bgRemove, setBgRemove] = useState("");
  const [bgRemove] = useState("");
  const fileInputRef = useRef(null);
  const [name, setName] = useState("Your Name");
  const ref = useRef(null);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const handleImageUpload = (event) => {
    if (event.target.files && event.target.files[0]) {
      setUserImage(URL.createObjectURL(event.target.files[0]));
    }
    console.log("Image Uploaded");
  };

  const downloadImage = useCallback(() => {
    const node = document.getElementById("swag-container");

    htmlToImage.toPng(node)
      .then((dataUrl) => {
        const link = document.createElement("a");
        link.download = "my-swag.png";
        link.href = dataUrl;
        link.click();
      })
      .catch((error) => {
        console.error("Oops, something went wrong!", error);
      });
  }, []);

  return (
    <section className="prize-section">
      <div className="prize-title-container">
        <h2 className="prize-section-title">HOW TO GENERATE</h2>
        <h1 className="prize-title">DIGITAL BADGE</h1>
      </div>


      <div className="flex items-center justify-center min-h-screen text-white">
        <div className="flex flex-col items-center w-full max-w-7xl p-6 space-y-8 md:flex-row md:space-y-0 md:space-x-16">
          <div className="flex-shrink-0 relative" ref={ref} id="swag-container">
            <div>
              <img
                src={swagCard}
                alt="Badge"
                width={449}
                height={550}
              />
              <img
                src={userImage || mohit}
                alt="User"
                className="absolute bottom-[6%] md:bottom-10 left-[20%] md:left-1/4 fade-bottom h-[320px] w-[280px] md:h-[380px] md:w-[300px] object-contain md:object-cover"
                height={320}
                width={320}
              />
              <p className="absolute bottom-[10%] left-1/3 font-medium text-3xl md:text-4xl lg:text-4xl tracking-wide opacity-90">{name}</p>
            </div>
          </div>

          <div className="flex flex-col items-center w-full space-y-4 md:items-start">
            <h1 className="text-xl font-bold">Introducing the HTM 5.0 Digital Badge!</h1>
            <p className="text-base font-light">Celebrate your membership in the prestigious International Hackathon Community with exclusive HTM 5.0 Digital Badge. To get your personalised e-badge, follow these steps:</p>
            <ol className="list-decimal list-inside text-base font-light">
              <li>Enter your name</li>
              <li>Upload your photo</li>
              <li>Download your Badge</li>
            </ol>
            <p className="text-base font-light">This exclusive badge is a great way to showcase your participation and achievements.</p>
            <p className="text-sm font-extralight text-opacity-70 pt-4 pb-4">*Note: Your privacy is our priority. We do not store your photos on our servers or use them in any other way that compromises your privacy.</p>

            <input
              type="text" placeholder="Enter your Name" className="w-full max-w-xs p-2 rounded text-white text-opacity-40 bg-transparent border border-gray-300"
              value={name}
              onFocus={e => e.target.select()}
              onChange={e => setName(e.target.value)}
            />
            <input
              ref={fileInputRef}
              type="file"
              accept="image/*"
              style={{display: "none"}}
              onChange={handleImageUpload}
              required={true}
            />
            
            <div className="flex space-x-4">
              <button
                className="bg-white p-2 px-4 rounded text-black"
                onClick={() => fileInputRef.current.click()}
              >
                Upload your photo
              </button>
              <button className="bg-gradient-to-b from-[#149087] to-[#0F4945] p-2 px-6 rounded" onClick={downloadImage}>Build it</button>
            </div>
            {bgRemove && (
              <div className="mb-4">
                <a
                  href={bgRemove}
                  download="background_removed_image.png"
                >
                  <button className="bg-[#EE5D4F] p-2 px-4 rounded">
                    Download
                  </button>
                </a>
              </div>
            )}
          </div>
        </div>
      </div>


      <div style={{width:'100%', marginTop: 20,marginBottom: 20,}}>
        <ul className="tab-list">
          <li className={activeTab === 'desktop' ? 'active' : ''}>
            <button href="#" onClick={() => handleTabClick('desktop')}>Desktop Wallpapers</button>
          </li>
          <li className={activeTab === 'mobile' ? 'active' : ''}>
            <button href="#" onClick={() => handleTabClick('mobile')}>Mobile Wallpapers</button>
          </li>
        </ul>

        <div className={activeTab === 'desktop' ? 'box-grid desktop-grid' : 'box-grid mobile-grid'}>{data[activeTab].slice(0, 9).map((box) => (
        // <div className="box-grid">{data[activeTab].slice(0, 9).map((box) => (
          <div key={box.id} className={`box-container ${activeTab === 'desktop' ? 'desktop-box' : 'mobile-box'}`}>
            <div className="box">
              <img 
                className={`box-image ${activeTab === 'desktop' ? 'desktop-image' : 'mobile-image'}`} 
                src={box.image} 
                alt={box.alt} 
              />
            </div>
          </div>
          ))}
        </div>


      </div>
      
      
    </section>
  );
};

export default Swags;
