import React from 'react';
import './SponsorPage.scss';
import Navbar from '../../components/Navbar/Navbar';
import Footer from '../../components/Footer/Footer';
import SponsorsLogo from '../../components/SponsorsLogos/SponsorsLogo';
import img from './background.png';


const SponsorPage = () => {
  return (
    <>
      <div className="meetup-container">
        <Navbar />
        <section className="meetup-section">
          <img
            src={img}
            alt="Meetup background"
            className="meetup-background"
          />
          <div className="meetup-content">
            <h1 className="meetup-title">MEET OUR SPONSORS</h1>
            <p className="meetup-subtitle">CREATE | COLLABORATE | COMMUNICATE</p>
          </div>
          <div className="whitish-overlay"></div>
        </section>
        <div className="gradient-overlay"></div>
      </div>
      
      <SponsorsLogo/>
      



      <Footer/>
    </>
  );
};

export default SponsorPage;
