import React from 'react';
import './OurPeople.scss';
import Navbar from '../../components/Navbar/Navbar';
import img from './team.png';
import Footer from '../../components/Footer/Footer';
import PeopleCard from '../../components/PeopleCard/PeopleCard';

const OurPeople = () => {
  return (
    <>
    <div className="judges-container">
      <Navbar />
      <section className="judges-section">
        <img
          src={img}
          alt="Judges session background"
          className="judges-background"
        />
        <div className="judges-content">
          <h1 className="judges-title">OUR PEOPLE</h1>
          <p className="judges-subtitle">CREATE | COLLABORATE | COMMUNICATE</p>
        </div>
        <div className="whitish-overlay"></div>
      </section>
      <div className="gradient-overlay"></div>
    </div>
   <PeopleCard />
    <Footer/>
    </>
  );
};

export default OurPeople;
