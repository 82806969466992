import React from 'react';
import './Schedule.scss';
import Navbar from '../../components/Navbar/Navbar';
import img from './Schedule.svg';
import Footer from '../../components/Footer/Footer';
import Timeline from '../../components/Timeline/Timeline1';

const Schedule = () => {
  return (
    <>
      <div className="schedule-container">
        <Navbar />
        <section className="schedule-section">
          <img
            src={img}
            alt="Schedule session background"
            className="schedule-background"
          />
          <div className="schedule-content">
            <h1 className="schedule-title">SCHEDULE</h1>
            <p className="schedule-subtitle">CREATE | COLLABORATE | COMMUNICATE</p>
          </div>
          <div className="whitish-overlay"></div>
        </section>
        <div className="gradient-overlay"></div>
      </div>
    <Timeline/>
      <Footer/>
    </>
  );
};

export default Schedule;
