import React from 'react';
import './MeetupPlaces.scss';
import aeroplane from './airplane.png';

const meetupData = [
  {
    city: "Rajkot",
    type: "Meetup",
    price: "Free",
    time: "8th Aug , 9:30 AM",
    venue: "Marwadi University, Rajkot",
    link:"https://lu.ma/zald4y46",
    status: "Happened! 🎉"
  },
  {
    city: "Indore",
    type: "Meetup",
    price: "Free",
    time: "5th Aug , 3:00 PM ",
    venue: "IPS Academy, Indore",
    link: "https://lu.ma/b4631mfq",
    status: "Happened! 🎉"
  },
  {
    city: "Ahmedabad",
    type: "Meetup",
    price: "Free",
    time: "17th Aug , 3:00 PM",
    venue: "York IE, Ahemdabad",
    link:"https://lu.ma/r9zbvn22",

    status: "Happened! 🎉"
  },
  {
    city: "Chandigarh",
    type: "Meetup",
    price: "Free",
    time: "16th July , 2:00 PM",
    venue: "Chitkara University",
    link:"/",
    status: "Happened! 🎉"
  },
];

const MeetupCard = ({ data }) => (
  <div className="meetup-card">
    <div className="meetup-card-header">
      <div>
        <h2 className="meetup-card-city">{data.city}</h2>
        <p className="meetup-card-type">{data.type}</p>
      </div>
      <p className="meetup-card-price">{data.price}</p>
    </div>
    <div className="meetup-card-image">
      <div className="circle-progress">
        <img src={aeroplane} alt="Aeroplane icon" />
      </div>
    </div>
    <div className="meetup-card-time">
      <div className="time-line"></div>
      <p>{data.time}</p>
      <div className="time-line"></div>
    </div>
    <div className="meetup-card-footer">
      <p className="meetup-card-venue"><b>Venue</b> - {data.venue}</p>
      <a href={data.link} target='_blank' rel='noreferrer' className='meetup-card-status'>{data.status}</a>
    </div>
  </div>
);

const MeetupPlaces = () => {
  return (
    <section className="meetup-places-section" id="meetup-places">
      <div className="meetup-places-title-container">
        <h2 className="meetup-places-section-title">WHY WE CONDUCTING</h2>
        <h1 className="meetup-places-title">MEETUPS</h1>
      </div>

      <section className="event-description">
        <h2 className="visually-hidden">Hack The Mountain Event Description</h2>
        <p>
          Hack The Mountain is bringing the meet-up party to your city to connect, create, and absolutely crush it together! HTM 5.0 is gearing up to surprise you in more ways than one, with something so groundbreaking that'll knock your socks off. 💡
        </p>
        <p>
          Join us in-person for the big reveals as this isn't just another tech gathering- it's a catalyst for creating extraordinary things. 🌟
        </p>
        <p>
          Immerse yourself in a day of unparalleled networking where ideas collide and friendships are forged. Pull yourself forward, share your vision, collaborate on pioneering projects, and be inspired by the brilliance of your peers.💡
        </p>
        <p>
          Fuel your creativity with delicious food and exclusive swags while connecting with like-minded individuals who share your passion for technology. Whether you're a seasoned developer, a curious novice, or simply someone eager to learn, there's a place for you at Hack The Mountain. 🌈
        </p>
        <p>
          Be part of a thriving tech community that's shaping the future. Together, we'll push boundaries, solve problems, and create rewarding solutions that matter. 🏆
        </p>
        <p className='event-new'>
          Register now and let's embark on this exciting journey together.
        </p>
      </section>

      <div className="meetup-cards-container">
        {meetupData.map((data, index) => (
          <MeetupCard key={index} data={data} />
        ))}
      </div>
    </section>
  );
}

export default MeetupPlaces;