import React, { useState, useEffect } from 'react';

const TruncatedText = ({ text, desktopMaxLength, mobileMaxLength, isHovered }) => {
  const [isTruncated, setIsTruncated] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const maxLength = isMobile ? mobileMaxLength : desktopMaxLength;
    setIsTruncated(text.length > maxLength);
  }, [text, desktopMaxLength, mobileMaxLength, isMobile]);

  const displayText = isTruncated && !isHovered
    ? `${text.slice(0, isMobile ? mobileMaxLength : desktopMaxLength)}...`
    : text;

  return (
    <div className={`truncated-text ${isTruncated ? 'truncated' : ''}`}>
      <span>{displayText}</span>
    </div>
  );
};

export default TruncatedText;
