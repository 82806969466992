import React, { useState } from 'react';
import './PeopleCard.scss';
import { FaLinkedin } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";

// Team
import mohit from './images/TeamMembers/mohit.webp';
import anmol from './images/TeamMembers/anmol.webp';
import avinash from './images/TeamMembers/avinash.webp';
import varsha from './images/TeamMembers/varsha.webp';
import ankit from './images/TeamMembers/ankit.webp';
import krishkhattar from './images/TeamMembers/krish_khattar.webp';
import krishgupta from './images/TeamMembers/krish_gupta.webp';
import rachit from './images/TeamMembers/rachit.webp';
import yashas from './images/TeamMembers/yashas.webp';
import anshika from './images/TeamMembers/anshika.webp';
import ayush from './images/TeamMembers/divy_ayush.webp';
import laksh from './images/TeamMembers/laksh.webp';
import aryan from './images/TeamMembers/aryan.webp';
import harsh_trivedi from './images/TeamMembers/harsh.webp';
import saumya from './images/TeamMembers/saumya.webp';
import disha from './images/TeamMembers/disha.webp';
import parag from './images/TeamMembers/parag.webp';
import deepanshu from './images/TeamMembers/deepanshu.webp';
import sahil from './images/TeamMembers/sahil.webp';
import piyush from './images/TeamMembers/piyush.webp';
import manas from './images/TeamMembers/manas.webp';
import kshitij from './images/TeamMembers/kshitij.webp';
import yash from './images/TeamMembers/yash.webp';
import khushal from './images/TeamMembers/khushal.webp';
import kartikaya from './images/TeamMembers/kartikeya.webp';
import tushar from './images/TeamMembers/tushar.webp';
import rohit from './images/TeamMembers/rohit.webp';
import praveen from './images/praveen.svg'
import shaishav from './images/shaishav.svg'

// Judges and Mentor
import anurag from './images/Anurag.webp' 
import harsh from './images/Harsh.webp'
import sarika from './images/sarika.webp'
import shivam from './images/shivam.webp'
// import shreyan from './images/shreyan.webp'
import devendara from './images/Devendra.webp'
import rishabh from './images/rishabh.webp'
import vanshika from './images/vanshika.webp'
import ishwarya from './images/Ishwariya.webp';
import mrunak from './images/Mrunank.webp'
import priyam from './images/Priyam.webp'
import sharmila from './images/sharmila.webp'
import sankalp from './images/sankalp.webp'
import raghav from './images/Raghav.webp'
import ayushi from './images/Ayushi.webp'
import prashant from './images/Prashant.webp'
import pranay from './images/prana.png'

import nikhil from './images/nikhil.webp'
import anita from './images/anita.webp'
import shivani from './images/shivani.webp'
import naksh from './images/naksh.webp'
import siddharth from './images/siddhartha.webp'
import vaibhav from './images/vaibhav.webp'
import arushi from './images/arushi.webp'
import puskar from './images/Puskar.webp'
import sejal from './images/sejal.webp'
import khushi from './images/khushi.webp'
import udayveer from './images/udayveer.webp'
import satyam from './images/satyam.webp'
import vidushi from  './images/viushi.webp'
import manav from './images/manav.webp'
import sriram from './images/sriram.png'


const JudgesCard = () => {
  const [selectedCategory, setSelectedCategory] = useState('team');

  const judgesData = {
    "team": [
      { image: mohit, name: 'Mohit Sharma', linkedin: 'https://www.linkedin.com/in/mohit-sharma1/', twitter: 'https://x.com/mohitsharma_09' },
      { image: anmol, name: 'Anmol Reshi', linkedin: 'https://www.linkedin.com/in/anmolreshi/', twitter: 'https://x.com/Anmolreshi_21' },
      { image: avinash, name: 'Avinash Koshal', linkedin: 'https://www.linkedin.com/in/avinashkoshal/', twitter: '' },
      { image: varsha, name: 'Varsha Sharma', linkedin: 'https://www.linkedin.com/in/varsha-sharma-2266021a4', twitter: '' },
      { image: ankit, name: 'Ankit Kapoor', linkedin: 'https://www.linkedin.com/in/ankit4kapoor/', twitter: 'https://x.com/ankit__kapoor47' },
      { image: krishkhattar, name: 'Krish Khattar', linkedin: 'https://www.linkedin.com/in/krishkhattar/', twitter: 'https://x.com/krish_khattar' },
      { image: krishgupta, name: 'Krish Gupta', linkedin: '', twitter: 'https://x.com/thetechieowl' },
      { image: rachit, name: 'Rachit Agarwal', linkedin: 'https://www.linkedin.com/in/rachitag112', twitter: 'https://www.twitter.com/rachitag112' },
      { image: yashas, name: 'Yashas Mittal', linkedin: 'https://www.linkedin.com/in/yashas-mittal-16a840236/', twitter: 'https://x.com/YashasMittal' },
      { image: anshika, name: 'Anshika Arora', linkedin: 'https://www.linkedin.com/in/anshika-arora-863404248', twitter: 'https://x.com/347ansha' },
      { image: ayush, name: 'Divy Ayush Kumar', linkedin: 'https://www.linkedin.com/in/divy-ayush-kumar', twitter: 'https://x.com/AyushDivy' },
      { image: laksh, name: 'Laksh Kapoor', linkedin: 'https://www.linkedin.com/in/laksh-kapoor/', twitter: 'https://x.com/laksh_kapoor_' },
      { image: aryan, name: 'Aryan Gajjar', linkedin: 'https://linktr.ee/aryangajjar', twitter: 'https://x.com/aryan15092002' },
      { image: harsh_trivedi, name: 'Harsh Trivedi', linkedin: 'https://www.linkedin.com/in/harsh-trivedi03', twitter: 'https://x.com/harshh_trivedi' },
      { image: saumya, name: 'Saumya Bhattacharya', linkedin: 'https://www.linkedin.com/in/saumya-bhattacharya-2b6bb522b/', twitter: 'https://x.com/SaumyaB_28' },
      { image: disha, name: 'Disha Verma', linkedin: 'https://www.linkedin.com/in/disha-verma-415435231', twitter: 'https://x.com/theflank08' },
      { image: parag, name: 'Parag Agrawal', linkedin: 'https://www.linkedin.com/in/parag-agrawal-101892202/', twitter: 'https://x.com/Parag_477' },
      { image: deepanshu, name: 'Deepanshu Tyagi', linkedin: 'https://www.linkedin.com/in/deepanshu-tyagi-2122ab268/', twitter: 'https://twitter.com/TheBackendMonk' },
      { image: sahil, name: 'Sahil Panwar', linkedin: 'https://www.linkedin.com/in/sahil-panwar-715370294', twitter: 'https://x.com/sahilpanwa58776?s=21' },
      { image: piyush, name: 'Piyush Sharma', linkedin: 'https://www.linkedin.com/in/piyush-sharma20/', twitter: 'https://x.com/Piysync810' },
      { image: manas, name: 'Manas Jha', linkedin: 'https://www.linkedin.com/in/manasjhamj', twitter: 'https://x.com/manasjhamj' },
      { image: kshitij, name: 'Kshitij Varma', linkedin: 'https://www.linkedin.com/in/kshitijvarma21/', twitter: 'https://twitter.com/kstij21' },
      { image: yash, name: 'Yash Singhal', linkedin: 'https://www.linkedin.com/in/yash-singhal-3bba42272/', twitter: 'https://x.com/YashSinghal2612' },
      { image: khushal, name: 'Khushal Khandelwal', linkedin: 'https://www.linkedin.com/in/khushal-khandelwal/', twitter: 'https://x.com/KhushalK_dev' },
      { image: kartikaya, name: 'Kumar Kartikeya', linkedin: 'https://www.linkedin.com/in/karthicc-bruh/', twitter: '' },
      { image: tushar, name: 'Tushar Gupta', linkedin: 'https://linkedin.com/in/tushar1504', twitter: 'https://x.com/tuszhhar' },
      { image: rohit, name: 'Rohit Kumar', linkedin: '', twitter: '' },
    ],
    "judges": [
      { image: anurag, name: 'Anurag Mishra', linkedin: 'https://www.linkedin.com/in/anuragayumishra/',twitter:'/' },
      { image: harsh, name: 'Harsh Soni', linkedin: 'https://www.linkedin.com/in/hashprog/' ,twitter:"https://x.com/harshSoni_tech"},
      { image: sarika, name: 'Sarika Solanki', linkedin: 'https://www.linkedin.com/in/sarika-solanki-685242266?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app' ,twiiter:'/'},
       
      { image: devendara, name: 'Devendara Pariha', linkedin: 'https://www.linkedin.com/in/devendra-parihar',twitter:'https://x.com/Devendr74154261'

      },
      { image:rishabh,
        name:'Rishabh Gupta',
        linkedin:'https://linkedin.com/in/rishabhgupta1',twitter:"/"

      },
      {
        image:vanshika,
        name:'Vanshika Rana',
        linkedin:'https://www.linkedin.com/in/vanshikarana/',twitter:'/'
      },
      { image: shivam, name: 'Shivam Goyal', linkedin: 'https://www.linkedin.com/in/shivamgoyall/' ,twitter:'/'},
   {
        image: ishwarya,
        name: 'Ishwarya Sundhararajan',
        linkedin:'https://www.linkedin.com/in/ishwarya-sundhararajan/',
        twitter:"/"

   },
   {
    image:sriram,
    name:'Sriram Santosh',
    linkedin:"https://www.linkedin.com/in/sriramsantosh/",
    twitter:'/'
   },
   { image:pranay,
    name:"Pranay Airan",
    linkedin:"https://www.linkedin.com/in/pranayairan/",
    twitter:'/'

   },
   {
    image: mrunak,
    name: 'Mrunank Jagdish Pawar',
    linkedin:'https://www.linkedin.com/in/mrunankpawar/',
    twitter:'/'
   },
  {
    image:priyam,
    name:'Priyam Ganguly',
    linkedin:'https://www.linkedin.com/in/priyam-ganguly-3743a566/'
   },
   {
    image:sharmila,
    name:'Sharmila Devi Chandariah',
    linkedin:'http://www.linkedin.com/in/sharmiladevichandariah',

   },
   {
    image:sankalp,
    name:'Sankalp Shrivastava',
    linkedin:'https://www.linkedin.com/in/shrivastavasankalp/'
   },
   {
    image:raghav,
    name:"Raghavendran Ramkrishnan",
    linkedin:'https://www.linkedin.com/in/raghavramki/',
    twitter:'/'
   },
   {
    image:ayushi,
    name:'Ayushi Agarwal',
    linkedin:'http://linkedin.com/in/ayushi-agarwal-883819106'
   },
   {
    image:anita,
    name:"Anita Routray",
    linkedin:"https://www.linkedin.com/in/anitaroutray"
   }
    ],
    "mentors": [
       {
        image: manav,
        name:'Manav Gupta',
        linkedin:'https://www.linkedin.com/in/--manav-gupta--/',
       },
       {
        image:prashant,
        name:'Prashant Bhavsar',
        linkedin:'https://www.linkedin.com/in/prashant-bhavsar-3o12/',
        twitter:"https://www.linkedin.com/in/prashant-bhavsar-3o12/"
       },
      { image: anurag, name: 'Anurag Mishra', linkedin: 'https://www.linkedin.com/in/anuragayumishra/',twitter:'/' },
      { image:rishabh,
        name:'Rishabh Gupta',
        linkedin:'https://linkedin.com/in/rishabhgupta1',twitter:"/"

      },

      { image: harsh, name: 'Harsh Soni', linkedin: 'https://www.linkedin.com/in/hashprog/' ,twitter:"https://x.com/harshSoni_tech"},
     
      
    { image:nikhil,
      name:'Nikhil Pentapalli',
      linkedin:"http://linkedin.com/in/nikhilpentapalli"
    },
    {
       image:praveen,
       name:"Praveen Singh",
       linkedin:"https://www.linkedin.com/in/singhh-praveen/"
    },
    { image:shaishav,
      name:"Shaishav Surati",
      linkedin:"https://www.linkedin.com/in/shaishavsurati/"
    },
    { image:shivani,
      name:"Shivani Singh",
      linkedin:"https://www.linkedin.com/in/shivani-singh-4a90991a7/"
  
    },
    {
      image:naksh,
      name:"Nakshatra Goel",
      linkedin:"https://www.linkedin.com/in/nakshatra-goel"
    },
    { image:siddharth,
      name:"Siddhartha Satyarthi",
      linkedin:"https://www.linkedin.com/in/colossalmonk/"
    },
    {
      image:vaibhav,
      name:"Vaibhav kadam",
      linkedin:"https://www.linkedin.com/in/4lph4v"
    },
    {
      image:arushi,
      name:"Arushi Garg",
      linkedin:"https://www.linkedin.com/in/arushi-garg105"
    },
    { image:puskar,
      name:"Puskar Roy",
      linkedin:"https://www.linkedin.com/in/puskar-roy"

    },
    {
  image:sejal,
  name:"Sajal Batra",
  linkedin:"https://linktr.ee/sajalbatra"
    },
    {
    image:khushi,
    name:'Khushi Panwar',
    linkedin:"https://www.linkedin.com/in/smilewithkhushi/",
    twitter:"https://twitter.com/SmileWithKhushi"
    },
    {
      image:udayveer,
      name:"Udayveer Singh",
      linkedin:"https://www.linkedin.com/in/udayveer-singh/"
    }
, {
  image:satyam,
  name:"Satyam Gupta",
  linkedin:"https://www.linkedin.com/in/satyam20/"
},{
  image:vidushi,
  name:"Vidushi Singhal",
  linkedin:"https://www.linkedin.com/in/vidushi-singhal-518011196/"
}

    ]
  };

  return (
    <section className="judges-cards">
      <div className="timeline-nav">
        <button 
          onClick={() => setSelectedCategory('team')} 
          className={selectedCategory === 'team' ? 'active' : ''}
        >
          Team
        </button>
        <button 
          onClick={() => setSelectedCategory('judges')} 
          className={selectedCategory === 'judges' ? 'active' : ''}
        >
          Judges
        </button>

        <button 
          onClick={() => setSelectedCategory('mentors')} 
          className={selectedCategory === 'mentors' ? 'active' : ''}
        >
          Mentors
        </button>

      </div>
      <div className="judges-content">
        <div className="judges-grid">
          {judgesData[selectedCategory].map((judge, index) => (
            <div key={index} className="judges-card">
              <img src={judge.image} alt={judge.name} className="judges-icon" />
              <h2 className="judges-card-title">{judge.name}</h2>
              {judge.twitter && (
                <a href={judge.twitter} className="judges-link1" target="_blank" rel="noopener noreferrer">
                  <i><FaXTwitter /></i>
                </a>
              )}
              {judge.linkedin && (
                <a href={judge.linkedin} className="judges-link2" target="_blank" rel="noopener noreferrer">
                  <i><FaLinkedin /></i>
                </a>
              )}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default JudgesCard;
