import React, { useState, useEffect ,useRef} from 'react';
import './Timeline1.scss';
const ToggleSwitch = ({ isOnline, setIsOnline }) => {
  return (
    <div className="toggle-switch">
      <button
        className={`toggle-option ${!isOnline ? 'active' : ''}`}
        onClick={() => setIsOnline(false)}
      >
        Online
      </button>
      <button
        className={`toggle-option ${isOnline ? 'active' : ''}`}
        onClick={() => setIsOnline(true)}
      >
        Offline
      </button>
      <div className={`toggle-slider ${isOnline ? 'slider-online' : 'slider-offline'}`}></div>
    </div>
  );
};

const Timeline = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [selectedDate, setSelectedDate] = useState('14th');
  const [isOnline, setIsOnline] = useState(true);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const onlineEvents = {
    "14th": [
      {
        type: 'simple',
        time: '6:00 AM',
        title: 'Check in starts',
        description: 'check in ends at 10:00 AM',
        positionClass: 'position-left',
        isInverted: false,
      },
      {
        type: 'inverted',
        time: '7:00 AM',
        title: 'Breakfast',
        description: 'Ends at 9:30 AM',
        positionClass: 'position-right',
        isInverted: true,
      },
      {
        type: 'simple',
        time: '10:00 AM',
        title: 'Opening Ceremony',
        description: 'Ceremony ends at 11:30 AM',
        positionClass: 'position-left',
        isInverted: false,
      },
      {
        type: 'inverted',
        time: '12:15 PM',
        title: 'Tech Talk 1',
        description: 'Ends at 12:45 PM',
        positionClass: 'position-right',
        isInverted: true,
      },
      {
        type: 'simple',
        time: '1:00 PM',
        title: 'Activity',
        description: 'Ends at 1:30 PM',
        positionClass: 'position-left',
        isInverted: false,
      },
      {
        type: 'inverted',
        time: '1:30 PM',
        title: 'Lunch',
        description: 'Ends at 2:30 PM',
        positionClass: 'position-right',
        isInverted: true,
      },
      {
        type: 'simple',
        time: '3:30 PM',
        title: 'Tech Talk 2',
        description: 'Ends at 4:30pm',
        positionClass: 'position-left',
        isInverted: false,
      },
      {
        type: 'inverted',
        time: '4:00 PM',
        title: 'MLH Activity',
        description: 'Ends at 4:30 PM',
        positionClass: 'position-right',
        isInverted: true,
      },
      {
        type: 'simple',
        time: '4:30 PM',
        title: 'Mentor Round',
        description: 'Ends at 6:00 PM',
        positionClass: 'position-left',
        isInverted: false,
      },
      {
        type: 'inverted',
        time: '6:15 PM',
        title: 'Activity',
        description: 'Ends at 6:30 PM',
        positionClass: 'position-right',
        isInverted: true,
      },
      {
        type: 'simple',
        time: '7:00 PM',
        title: 'Dinner',
        description: 'Ends at 8:30 PM',
        positionClass: 'position-left',
        isInverted: false,
      },
      {
        type: 'inverted',
        time: '9:00 PM',
        title: 'DJ Night',
        description: 'Ends at 10:00 PM',
        positionClass: 'position-right',
        isInverted: true,
      },
      {
        type: 'simple',
        time: '11:30 PM',
        title: 'Activity',
        description: 'Ends at 12:00 AM',
        positionClass: 'position-left',
        isInverted: false,
      },
    ],
    "15th": [
      {
        type: 'simple',
        time: '1:30 AM',
        title: 'Jamming',
        description: 'Ends at 2:00 AM',
        positionClass: 'position-left',
        isInverted: false,
      },
      {
        type: 'inverted',
        time: '7:00 AM',
        title: 'Breakfast',
        description: 'Ends at 9:30pm',
        positionClass: 'position-right',
        isInverted: true,
      },
      {
        type: 'simple',
        time: '9:30 AM',
        title: 'Mentor Round 2',
        description: 'Ends at 11:30 AM',
        positionClass: 'position-left',
        isInverted: false,
      },
      {
        type: 'inverted',
        time: '11:00 AM',
        title: 'Soft Deadline',
        description: 'Ends at 11:30am',
        positionClass: 'position-right',
        isInverted: true,
      },
      {
        type: 'simple',
        time: '12:00 AM',
        title: 'Activity',
        description: 'ends at 12:30 PM',
        positionClass: 'position-left',
        isInverted: false,
      },
      {
        type: 'inverted',
        time: '12:30 PM',
        title: 'Project Sumission Closes',
        description: 'before 1:00 PM',
        positionClass: 'position-right',
        isInverted: true,
      },
      {
        type: 'simple',
        time: '1:30 PM',
        title: 'Lunch',
        description: 'Ends at 3:00 PM',
        positionClass: 'position-left',
        isInverted: false,
      },
      {
        type: 'inverted',
        time: '3:00 PM',
        title: 'Judging ends',
        description: 'Ends at 4:30 PM',
        positionClass: 'position-right',
        isInverted: true,
      },
      {
        type: 'simple',
        time: '4:30 PM',
        title: 'Closing Ceremony',
        description: 'ends at 6:00 PM',
        positionClass: 'position-left',
        isInverted: false,
      },
      {
        type: 'inverted',
        time: '6:00 PM',
        title: 'Group Pictures',
        description: 'ends at 7:00 PM',
        positionClass: 'position-right',
        isInverted: true,
      },
      {
        type: 'simple',
        time: '7:30 PM',
        title: 'HTM 5.0 Ends with Happy Faces :)',
        description: 'ends at 12:00am',
        positionClass: 'position-left',
        isInverted: false,
      },
    ],
  };
  const offlineEvents = {
    "14th": [
      {
        type: 'simple',
        time: '6:00 AM',
        title: 'Hacking starts',
        description: 'check in ends at 10:00 AM',
        positionClass: 'position-left',
        isInverted: false,
      },
      {
        type: 'inverted',
        time: '10:00 AM',
        title: 'Opening Ceremony',
        description: 'ends at 11:30 AM',
        positionClass: 'position-right',
        isInverted: true,
      },
      {
        type: 'simple',
        time: '12:15 PM',
        title: 'Fun Game',
        description: 'Ends at 12:45 PM',
        positionClass: 'position-left',
        isInverted: false,
      },
      {
        type: 'inverted',
        time: '1:00 PM',
        title: 'Session Talk-1 (GFG Team)',
        description: 'ends at 1:30 PM',
        positionClass: 'position-right',
        isInverted: true,
      },
      {
        type: 'simple',
        time: '3:00 PM',
        title: '5 min online session by Blockchained India',
        description: 'Ends at 3:10 PM',
        positionClass: 'position-left',
        isInverted: false,
      },
      {
        type: 'inverted',
        time: '3:30 PM',
        title: 'Sponsor Talk-2(Upsurge)',
        description: 'ends at 4:00 PM',
        positionClass: 'position-right',
        isInverted: true,
      },
      {
        type: 'simple',
        time: '4:30 PM',
        title: 'Fun Game(Discord Team)',
        description: 'Ends at 5:00 PM',
        positionClass: 'position-left',
        isInverted: false,
      },
      {
        type: 'inverted',
        time: '5:00 PM',
        title:'Mentor Round 1',
        description: 'ends at 6:30 PM',
        positionClass: 'position-right',
        isInverted: true,
      },
      {
        type: 'simple',
        time: '6:30 PM',
        title: 'Session Talk-2(Reskill Team)',
        description: 'Ends at 7:30 PM',
        positionClass: 'position-left',
        isInverted: false,
      },
      {
        type: 'inverted',
        time: '8:00 PM',
        title:'Fun Game(Discord Team)',
        description: 'ends at 8:30 PM',
        positionClass: 'position-right',
        isInverted: true,
      },
      {
        type: 'simple',
        time: '11:00 PM',
        title: 'Movie Night',
        description: 'Ends at 1:00 AM',
        positionClass: 'position-left',
        isInverted: false,
      },
      // ... add more offline events for the 14th
    ],
    "15th": [
      {
        type: 'simple',
        time: '9:30 AM',
        title: 'Fun Game(Discord Team)',
        description: 'Ends at 10:00 AM',
        positionClass: 'position-left',
        isInverted: false,
      },
      {
        type: 'inverted',
        time: '11:00 AM',
        title:'Session Talk -4 (Reskill Team)',
        description: 'ends at 12:00 PM',
        positionClass: 'position-right',
        isInverted: true,
      },
      {
        type: 'simple',
        time: '12:30 AM',
        title: 'Fun Game(Discord Team)',
        description: 'Ends at 10:00 AM',
        positionClass: 'position-left',
        isInverted: false,
      },
      {
        type: 'inverted',
        time: '3:00 PM',
        title:'Mentor Round 2',
        description: 'ends at 4:30 PM',
        positionClass: 'position-right',
        isInverted: true,
      },
      {
        type: 'simple',
        time: '8:00 PM',
        title: 'Soft Deadline',
        description: 'Ends at 9:00 PM',
        positionClass: 'position-left',
        isInverted: false,
      },
      {
        type: 'inverted',
        time: '11:00 PM',
        title:'Project Submission',
        description: 'ends at 12:00 AM',
        positionClass: 'position-right',
        isInverted: true,
      },
      // ... add more offline events for the 15th
    ],
  };

  
  const currentEvents = isOnline ? onlineEvents : offlineEvents;

  const TimelineSegment = ({ isInverted }) => {
    const [mouseY, setMouseY] = useState(0);
    const svgRef = useRef(null); // Create a ref for the SVG element
  
    useEffect(() => {
      
      const handleMouseMove = (e) => {
        const rect = svgRef.current.getBoundingClientRect();
        const y = e.clientY - rect.top; // Mouse Y relative to SVG top
        console.log('Mouse Y:', y); // Debugging line
          setMouseY(y);
      };
    
      const svgElement = svgRef.current;
      console.log('SVG Element:', svgElement); // Debugging line
    
      if (svgElement) {
        svgElement.addEventListener('mousemove', handleMouseMove);
      }
    
      return () => {
        if (svgElement) {
          svgElement.removeEventListener('mousemove', handleMouseMove);
        }
      };
    }, []);
  
    const pathD = isInverted
      ? "M 475 0 L 475 200 Q 475 250, 425 250 L 125 250 Q 75 250, 75 300"
      : "M 75 0 L 75 200 Q 75 250, 125 250 L 425 250 Q 475 250, 475 300";
  
    const circle1Cx = isInverted ? 475 : 75;
    const circle1Cy = 200;
  
    const circle2Cx = isInverted ? 75 : 475;
    const circle2Cy = 300;
  
    const svgWidth = isMobile ? 300 : 500;
    const svgHeight = isMobile ? 200 : 400;
    const gradientStop1 = Math.min(mouseY / svgHeight, 1) * 100;
    const gradientStop2 = Math.max(mouseY / svgHeight, 0) * 100;
  
    return (
      <svg
        ref={svgRef} // Attach the ref to the SVG element
        width={svgWidth}
        height={svgHeight}
        viewBox="0 0 500 400"
        xmlns="http://www.w3.org/2000/svg"
        className="timeline-segment"
      >
        <defs>
          <linearGradient id="dynamic-gradient" x1="0%" y1="0%" x2="0%" y2="100%">
            <stop offset={`${gradientStop1}%`} stopColor="#0A8D81" />
            <stop offset={`${gradientStop2}%`} stopColor="#FFFFFF" />
          </linearGradient>
        </defs>
        <path
          d={pathD}
          stroke="url(#dynamic-gradient)"
          strokeWidth={isMobile ? 4 : 8}
          fill="none"
        />
        <circle cx={circle1Cx} cy={circle1Cy} r={isMobile ? 10 : 20} fill="#ffffff" />
        <circle cx={circle1Cx} cy={circle1Cy} r={isMobile ? 5 : 10} fill="#0A8D81" />
        <circle cx={circle2Cx} cy={circle2Cy} r={isMobile ? 10 : 20} fill="#ffffff" />
        <circle cx={circle2Cx} cy={circle2Cy} r={isMobile ? 5 : 10} fill="#0A8D81" />
      </svg>
    );
  };
  const EventCard = ({ type, time, title, description, positionClass }) => {
    return (
      <div className={`${positionClass} event-card-wrapper`}>
        <div className={`event-card-inner ${type === 'inverted' ? 'inverted-card-inner' : 'simple-card-inner'}`}>
          <div className="event-content">
            <header className="event-header">
              <p className="event-time">
                <span>Starts at</span> {time}
              </p>
              <h2 className="event-title">{title}</h2>
              <p className="event-description" style={{ textAlign: type === 'inverted' ? 'end' : 'start' }}>{description}</p>
            </header>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="timeline-container">
      <ToggleSwitch isOnline={isOnline} setIsOnline={setIsOnline} />
      <div className="htm-timeline-nav">
        <button onClick={() => setSelectedDate('14th')} className={selectedDate === '14th' ? 'active' : ''}>
          14 September , 2024
        </button>
        <button onClick={() => setSelectedDate('15th')} className={selectedDate === '15th' ? 'active' : ''}>
          15 September , 2024
        </button>
      </div>

      <div className="timeline-content">
        {currentEvents[selectedDate].map((event, index) => (
          <div className="timeline-step" key={index}>
            <TimelineSegment isInverted={event.isInverted} />
            <EventCard
              type={event.type}
              time={event.time}
              title={event.title}
              description={event.description}
              positionClass={event.positionClass}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Timeline;