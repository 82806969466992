import React,{useState} from 'react';
import './PrizePool.scss';
import thirdPlace from './third.svg';
import firstPlace from './first.svg';
import secondPlace from './second.svg';
import image from './image.png'

const PrizeCard = ({ prize, index }) => {
  const [isFlipped, setIsFlipped] = useState(false);

  const handleFlip = () => {
    setIsFlipped(!isFlipped);
  };

  return (
    <article className={`prize-card ${isFlipped ? 'is-flipped' : ''}`} key={index}>
      <div className="prize-card-inner">
        <div className="prize-card-front">
          <img src={prize.image} className="prize-image" alt={`Prize ${index + 1}`} />
          <h2 className="prize-amount">{prize.amount}</h2>
          {prize.details.map((detail, i) => (
            <React.Fragment key={i}>
              <p className="prize-detail">{detail}</p>
              {i < prize.details.length - 1 && <hr className="prize-divider" />}
            </React.Fragment>
          ))}
          {/* <button className="prize-view-more-btn" onClick={handleFlip}>View More</button> */}
        </div>
        <div className="prize-card-back">
          <h3>Additional Information</h3>
          <p>This is where you can add more details about the prize.</p>
          <button className="prize-view-more-btn" onClick={handleFlip}>Back</button>
        </div>
      </div>
    </article>
  );
};

const PrizePool = () => {
  const prizes = [
    { image: thirdPlace, amount: '₹100K', details: ['₹15K Cash', 'Exclusive HTM Merchandise', 'Software License Access'] },
    { image: firstPlace, amount: '₹150K', details: ['₹35K Cash','Exclusive HTM Merchandise', 'Exclusive Winner Party', 'Software License Access'] },
    { image: secondPlace, amount: '₹120K', details: ['₹25K Cash', 'Exclusive HTM Merchandise', 'Software License Access'] },
  ];

  const prizesOnline = [
    { image: thirdPlace, amount: '₹30K', details: ['₹10K Cash', 'Exclusive HTM Merchandise', 'Software License Access'] },
    { image: firstPlace, amount: '₹70K', details: ['₹25K Cash','Exclusive HTM Merchandise', 'Exclusive Winner Party', 'Software License Access'] },
    { image: secondPlace, amount: '₹50K', details: ['₹15K Cash', 'Exclusive HTM Merchandise', 'Software License Access'] },
  ];

  const otherPrizes = [
    { image: image, amount: '₹30K', details: [ ' Best Beginner Team','₹5K Cash', 'Exclusive HTM Merchandise', 'Software License Access'] },
    { image: image, amount: '₹30K', details: [ 'Best All Girls Team','₹5K Cash','Exclusive HTM Merchandise', 'Software License Access'] },
    { image: image, amount: '₹30K', details: ['Best Environment Team',  '₹5K Cash','Exclusive HTM Merchandise', 'Software License Access'] },
  ];

  return (
    <section className="prize-section">
      <div className="prize-title-container">
        <h2 className="prize-section-title">CHAMPION'S REWARD</h2>
        <h1 className="prize-title">PRIZE POOL</h1>
      </div>
      <div className="prize-category">
        <h2 className="prize-category-title">HTM OFFLINE POOL</h2>
        <div className="reward-grid">
          {prizes.map((prize, index) => (
            <PrizeCard key={index} prize={prize} index={index} />
          ))}
        </div>
      </div>
      <div className="prize-category">
        <h2 className="prize-category-title">HTM ONLINE POOL</h2>
        <div className="reward-grid">
          {prizesOnline.map((prize, index) => (
            <PrizeCard key={index} prize={prize} index={index} />
          ))}
        </div>
      </div>
      <div className="prize-category">
        <h2 className="prize-category-title">HTM PRIZES</h2>
        <div className="reward-grid">
          {otherPrizes.map((prize, index) => (
            <PrizeCard key={index} prize={prize} index={index} />
          ))}
        </div>
      </div>
    </section>);
};

export default PrizePool;