import React from 'react';
import './SponsorsLogos.scss';

// League Partner
import mlhLogo from './logos/mlh-logo-color.webp';

// Diamond Partner
import upsurgeLogo from './logos/upsurge.webp';

// Gold Partner
import logotechLogo from './logos/logitech.webp';
import geeks from './logos/geeksforgeeks.webp'

// Bronze Partner
import qoneqt from './logos/qoneqt.webp'

// Blockchain Partner
import tezosLogo from './logos/tezos.webp';

// General Sponsors
import wolframLogo from './logos/wolfram.webp';
import jetbrainsLogo from './logos/jetbrains.webp';
import axureLogo from './logos/axure.webp';
import mongo from './logos/mongodb.webp';
import godday from './logos/godaddy-registry.webp';
import auth0 from './logos/auth0.webp'
import streamlit from './logos/streamlit.webp'
import tinymce from './logos/tinymce.webp'
import balsamiq from './logos/balsamiq.webp'
import interviewCake from './logos/interview-cake.webp'
import AoPS from './logos/AoPS.webp'
import nordpass from './logos/NordPass.webp'
import nordvpn from  './logos/NordVpn.webp'
import saily from './logos/Saily.webp'
import voiceflow from  './logos/voiceflow.webp'
import icogni from './logos/incogni.webp'
import devlearn from './logos/devlearn.png'

// Community Partners
import reskillLogo from './logos/reskilll-logo.webp';
import azureDevLogo from './logos/azdev-logo.webp';
import blockchainIndia from './logos/blockchained-india.webp'
import cn_cuiet from './logos/CN_CUIET.webp'
import gfg from './logos/gfg.webp'
import perogative from './logos/PreroGative.webp'
import devarmy from './logos/devarmy.webp'
import bhopal from './logos/bhopal.webp'
import cm from './logos/cm.webp'
import gdgGuwahati from './logos/GDG guwati.webp'
import acm from './logos/acm.webp'
import openSourceChandigarh from './logos/6_Open Source Chandigarh.jpg'
import flutter from './logos/16_FlutterJaipur.png'
import techbluttei from './logos/20_TechBulletin.png'

// Incubation Partners
import gsbtm from './logos/incubation/gsbtm.webp'
import iic from './logos/incubation/iic.webp'
import savli from './logos/incubation/savli.webp'
import ssip from './logos/incubation/SSIP.webp'
import ministryofeducation from './logos/incubation/ministryofeducation.webp'
import nexus from './logos/12_Nexus.jpg'
// Knowledge Partners
import oracle from './logos/oracle.webp'

// Platform Partners
import dorahacks from './logos/dora.webp'

// Certificate Partners
import giveMyCertificate from './logos/givemycertificate.webp'

// Media Partners
import droomdroom from './logos/DroomDroom.webp'


const sponsorsData = {
  leaguePartner: [
    { name: 'MLH', logo: mlhLogo }
  ],
  diamond: [
    { name: 'Upsurge', logo: upsurgeLogo }
  ],
  gold: [{ name: 'Logotech', logo: logotechLogo },
    {name:'GeeksForGeek',logo:geeks}
  ],
  bronze:[
    { name: 'Qoneqt', logo: qoneqt }
  ],
  blockchainPartner: [
    { name: 'Tezos', logo: tezosLogo }
  ],
  generalSponsors: [
    { name: 'Wolfram', logo: wolframLogo },
    { name: 'Jetbrains', logo: jetbrainsLogo },
    { name: 'Axure', logo: axureLogo },
    { name: 'MongoDB', logo: mongo },
    { name: 'Godaddy', logo: godday },
    { name: 'Auth0', logo: auth0 },
    { name: 'Streamlit', logo: streamlit },
    { name: 'TinyMCE', logo: tinymce },
    { name: 'Balsamiq', logo: balsamiq },
    { name: 'Interview Cake', logo:interviewCake},
    { name: 'AoPS', logo:AoPS},
    { name:'NordPass',logo:nordpass},
    {name:'NordVpn',logo:nordvpn},
    {name:'Saily',logo:saily},
    {name:'Voiceflow',logo:voiceflow},
    {name:'Incogni',logo:icogni}
  ],
  communityPartners: [
    { name: 'Reskill', logo: reskillLogo },
    { name: 'Azure Developer Community', logo: azureDevLogo },
    { name: 'Blockchain India', logo: blockchainIndia },
    { name: 'CN CUIET', logo:cn_cuiet},
    { name: 'GFG', logo:gfg},
    {name:'PeroGative' ,logo:perogative},
    {name:'TheDevArmy',logo:devarmy},
    {name:"Bhopal", logo:bhopal },
    {name:"cm",logo:cm},
    {name:"gdg Guwahati",logo:gdgGuwahati},
    {name:"acm",logo:acm},
    {name:"Open Source Chandigarh",logo:openSourceChandigarh},
    {
      name: 'DevLearn',
      logo: devlearn
    },
    {
      name: 'Flutter Jaipur',
      logo: flutter
    },
    {
      name: 'Tech Bulletin',
      logo: techbluttei
    },
    {
      name: 'Nexus',
      logo: nexus
    }
  ],
  incubationPartners: [
    { name: 'Ministry of Education', logo: ministryofeducation },
    {name:"SSPI",logo: ssip},
    { name: 'IIC', logo: iic},
    {name:"Savli",logo: savli},
    { name: 'GSBTM', logo: gsbtm},
  ],
  knowledgePartners:[
     {name:"Oracle",logo:oracle}
  ],

  platformPartners:[
    {name:"DoraHacks",logo:dorahacks}
  ],
  certificatePartners:[
    {name:"Give my certificate",logo:giveMyCertificate}
  ],
  mediaPartners:[
    {name:"Droom Droom",logo: droomdroom}
  ],
};

const LeaguePartner = () => (
  <div className="htm-sponsors-sponsor-category league-partner">
    <h2 className="htm-sponsors-title htm-sponsors-league-partner-title">League Partner</h2>
    <div className="htm-sponsors-sponsor-logos htm-sponsors-league-partner-logos">
      {sponsorsData.leaguePartner.map((sponsor, index) => (
        <div key={index} className="htm-sponsors-sponsor-logo">
          <img src={sponsor.logo} alt={sponsor.name} />
        </div>
      ))}
    </div>
  </div>
);

const DiamondSponsors = () => (
  <div className="htm-sponsors-sponsor-category diamond">
    <h2 className="htm-sponsors-title htm-sponsors-diamond-title">Diamond Sponsors</h2>
    <div className="htm-sponsors-sponsor-logos htm-sponsors-diamond-logos">
      {sponsorsData.diamond.map((sponsor, index) => (
        <div key={index} className="htm-sponsors-sponsor-logo">
          <img src={sponsor.logo} alt={sponsor.name} />
        </div>
      ))}
    </div>
  </div>
);

const GoldSponsors = () => (
  <div className="htm-sponsors-sponsor-category gold">
    <h2 className="htm-sponsors-title htm-sponsors-gold-title">Gold Sponsors</h2>
    <div className="htm-sponsors-sponsor-logos htm-sponsors-gold-logos">
      {sponsorsData.gold.map((sponsor, index) => (
        <div key={index} className="htm-sponsors-sponsor-logo">
          <img src={sponsor.logo} alt={sponsor.name} />
        </div>
      ))}
    </div>
  </div>
);
const BronzeSponsors = () => (
  <div className="htm-sponsors-sponsor-category bronze">
    <h2 className="htm-sponsors-title htm-sponsors-bronze-title">Bronze Sponsors</h2>
    <div className="htm-sponsors-sponsor-logos htm-sponsors-bronze-logos">
      {sponsorsData.bronze.map((sponsor, index) => (
        <div key={index} className="htm-sponsors-sponsor-logo">
          <img src={sponsor.logo} alt={sponsor.name} />
        </div>
      ))}
    </div>
  </div>
);


const BlockchainPartners = () => (
  <div className="htm-sponsors-sponsor-category blockchain-partner">
    <h2 className="htm-sponsors-title htm-sponsors-blockchain-partner-title">Blockchain Partner</h2>
    <div className="htm-sponsors-sponsor-logos htm-sponsors-blockchain-partner-logos">
      {sponsorsData.blockchainPartner.map((sponsor, index) => (
        <div key={index} className="htm-sponsors-sponsor-logo">
          <img src={sponsor.logo} alt={sponsor.name} />
        </div>
      ))}
    </div>
  </div>
);

const GeneralSponsors = () => (
  <div className="htm-sponsors-sponsor-category general-sponsors">
    <h2 className="htm-sponsors-title htm-sponsors-general-sponsors-title">General Sponsors</h2>
    <div className="htm-sponsors-sponsor-logos htm-sponsors-general-sponsors-logos">
      {sponsorsData.generalSponsors.map((sponsor, index) => (
        <div key={index} className="htm-sponsors-sponsor-logo">
          <img src={sponsor.logo} alt={sponsor.name} />
        </div>
      ))}
    </div>
  </div>
);

const CommunityPartners = () => (
  <div className="htm-sponsors-sponsor-category community-partners">
    <h2 className="htm-sponsors-title htm-sponsors-community-partners-title">Community Partners</h2>
    <div className="htm-sponsors-sponsor-logos htm-sponsors-community-partners-logos">
      {sponsorsData.communityPartners.map((sponsor, index) => (
        <div key={index} className="htm-sponsors-sponsor-logo">
          <img src={sponsor.logo} alt={sponsor.name} />
        </div>
      ))}
    </div>
  </div>
);
const CertificatePartners = () => (
  <div className="htm-sponsors-sponsor-category certificate-partners">
    <h2 className="htm-sponsors-title htm-sponsors-certificate-partners-title">Certificate Partners</h2>
    <div className="htm-sponsors-sponsor-logos htm-sponsors-certificate-partners-logos">
      {sponsorsData.certificatePartners.map((sponsor, index) => (
        <div key={index} className="htm-sponsors-sponsor-logo">
          <img src={sponsor.logo} alt={sponsor.name} />
        </div>
      ))}
    </div>
  </div>
);
const PlatformPartners = () => (
  <div className="htm-sponsors-sponsor-category platform-partners">
    <h2 className="htm-sponsors-title htm-sponsors-platform-partners-title">Platform Partners</h2>
    <div className="htm-sponsors-sponsor-logos htm-sponsors-platform-partners-logos">
      {sponsorsData.platformPartners.map((sponsor, index) => (
        <div key={index} className="htm-sponsors-sponsor-logo">
          <img src={sponsor.logo} alt={sponsor.name} />
        </div>
      ))}
    </div>
  </div>
);
const KnowledgePartners = () => (
  <div className="htm-sponsors-sponsor-category knowledge-partners">
    <h2 className="htm-sponsors-title htm-sponsors-knowledge-partners-title">Knowledge Partners</h2>
    <div className="htm-sponsors-sponsor-logos htm-sponsors-knowledge-partners-logos">
      {sponsorsData.knowledgePartners.map((sponsor, index) => (
        <div key={index} className="htm-sponsors-sponsor-logo">
          <img src={sponsor.logo} alt={sponsor.name} />
        </div>
      ))}
    </div>
  </div>
);


const MediaPartners = () => (
  <div className="htm-sponsors-sponsor-category platform-partners">
    <h2 className="htm-sponsors-title htm-sponsors-platform-partners-title">Media Partners</h2>
    <div className="htm-sponsors-sponsor-logos htm-sponsors-platform-partners-logos">
      {sponsorsData.mediaPartners.map((sponsor, index) => (
        <div key={index} className="htm-sponsors-sponsor-logo">
          <img src={sponsor.logo} alt={sponsor.name} />
        </div>
      ))}
    </div>
  </div>
);

const IncubationPartners = () => (
  <div className="htm-sponsors-sponsor-category incubation-partners">
    <h2 className="htm-sponsors-title htm-sponsors-incubation-partners-title">Incubation Partners</h2>
    <div className="htm-sponsors-sponsor-logos htm-sponsors-incubation-partners-logos">
      {sponsorsData.incubationPartners.map((sponsor, index) => (
        <div key={index} className="htm-sponsors-sponsor-logo">
          <img src={sponsor.logo} alt={sponsor.name} />
        </div>
      ))}
    </div>
  </div>
);


const SponsorPage = () => {
  return (
    <div className="htm-sponsors-sponsors-container">
      <div className="htm-sponsors-sponsors-wrapper">
        <DiamondSponsors />
        <GoldSponsors />
        <BronzeSponsors />
        <BlockchainPartners />
        <GeneralSponsors />
        <LeaguePartner />
        <CertificatePartners/>
        <PlatformPartners/>
        <CommunityPartners />
        <IncubationPartners />
        <KnowledgePartners />
        <MediaPartners />
       
      </div>
    </div>
  );
};

export default SponsorPage;
